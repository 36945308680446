import OrderReference from '~/src/components/orders/OrderReference'
import OrderTotal from '~/src/components/orders/OrderTotal'
import { Box, BoxProps } from '@mui/material'
import { OrderSummary } from '@eo-storefronts/eo-core'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderListItemBase = ({ orderSummary, sx = {}, ...boxProps }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '> div': {
          display: 'flex',
          alignItems: 'baseline',
          flexWrap: 'wrap',
          flexDirection: 'column'
        },
        [muiTheme.breakpoints.up('sm')]: {
          height: '100%'
        },
        [muiTheme.breakpoints.down('sm')]: {
          '> div': {
            flexDirection: 'row',
            gap: .5
          }
        },
        ...sx
      }}
      {...boxProps}>
      <OrderReference orderSummary={orderSummary} />
      <OrderTotal
        orderSummary={orderSummary}
        includePaymentMethod={false}
      />
    </Box>
  )
}

export default OrderListItemBase
