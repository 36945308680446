import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps {
  orderSummary: OrderSummary,
}

const OrderPaymentMethod = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.payment_method')}
      </Typography>
      <Typography variant='h6'>
        {orderSummary.payment_method.name.toLowerCase()}
      </Typography>
    </Box>
  )
}

export default OrderPaymentMethod
