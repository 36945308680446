import { Fragment } from 'react'
import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, Divider } from '@mui/material'
import OrderListItem from '~/src/components/orders/order-list/OrderListItem'

interface Props {
  orders: OrderSummary[],
}

const OrderList = ({ orders }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      {orders.map((order: OrderSummary, index: number) => (
        <Fragment key={order.id}>
          <OrderListItem orderSummary={order} />

          {orders.length - 1 !== index && (<Divider />)}
        </Fragment>
      ))}
    </Box>
  )
}

export default OrderList
