import { OrderSummary } from '@eo-storefronts/eo-core'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { Divider, IconButton, ListItem, ListItemButton, Theme, useMediaQuery } from '@mui/material'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import OrderListItemBase from '~/src/components/orders/order-list/OrderListItemBase'
import OrderListItemInfo from '~/src/components/orders/order-list/OrderListItemInfo'

interface Props {
  orderSummary: OrderSummary,
}

const OrderListItem = ({ orderSummary }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()

  const _handleOnClick = () => {
    push(resolve(`${RoutesEnum.ORDERS}/${orderSummary.id}/${RoutesEnum.TRACKER}`))
  }

  return (
    <ListItem
      onClick={_handleOnClick}
      disablePadding
      secondaryAction={
        <IconButton color='primary' edge='end' aria-label='details'>
          <ArrowForwardIosOutlinedIcon />
        </IconButton>
      }
    >
      <ListItemButton
        disableRipple
        sx={{
          borderRadius: 'var(--btn-border-radius)',
          display: 'grid',
          gridTemplateAreas: '"base divider info"',
          columnGap: 1,
          gridTemplateColumns: 'repeat(2, max-content) 1fr',
          gridTemplateRows: 'auto',
          [muiTheme.breakpoints.down('sm')]: {
            gridTemplateAreas: '' +
              '"base"' +
              '"info"',
            gridTemplateColumns: '1fr'
          }
        }}>
        <OrderListItemBase
          gridArea='base'
          orderSummary={orderSummary} />

        {!isSmallDevice && (
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              gridArea: 'divider'
            }} />
        )}

        <OrderListItemInfo
          gridArea='info'
          orderSummary={orderSummary} />
      </ListItemButton>
    </ListItem>
  )
}

export default OrderListItem
