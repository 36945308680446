import { OrderSummary } from '@eo-storefronts/eo-core'
import OrderLocation from '~/src/components/orders/OrderLocation'
import { Box, BoxProps } from '@mui/material'
import OrderDeliveryMethodResume from '~/src/components/orders/OrderDeliveryMethodResume'
import OrderCreatedAt from '~/src/components/orders/OrderCreatedAt'
import OrderPaymentMethod from '~/src/components/orders/OrderPaymentMethod'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import OrderAgainButton from '~/src/components/orders/order-again-button'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderListItemInfo = ({ orderSummary, sx = {}, ...boxProps }: Props) => {
  const muiTheme = useCustomMuiTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        '> div': {
          display: 'flex',
          alignItems: 'baseline',
          gap: .5,
          flexWrap: 'wrap'
        },
        [muiTheme.breakpoints.up('sm')]: {
          height: '100%'
        },
        ...sx
      }}
      {...boxProps}>
      <OrderLocation orderSummary={orderSummary} />
      <Box sx={{
        textTransform: 'lowercase',
        '> div': {
          display: 'flex',
          alignItems: 'baseline',
          gap: .5,
          flexWrap: 'wrap',
          '&:first-of-type': {
            '>:first-of-type': {
              textTransform: 'none'
            }
          }
        }
      }}>
        <OrderDeliveryMethodResume orderSummary={orderSummary} />
      </Box>
      <OrderCreatedAt orderSummary={orderSummary} />
      <OrderPaymentMethod orderSummary={orderSummary} />
      <OrderAgainButton orderSummary={orderSummary} />
    </Box>
  )
}

export default OrderListItemInfo
