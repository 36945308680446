import { useRef } from 'react'
import NoOrderFound from '~/src/components/orders/NoOrderFound'
import OrderList from '~/src/components/orders/order-list'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useFetchAllOrders } from '~/src/hooks/orders/useFetchAllOrders'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { ORDERS_BY_DATE_SELECTOR } from '~/src/stores/orders'
import useGetOrderTemplate from '~/src/components/orders/order-layout/styles/useGetOrderTemplate'
import InPageLoader from '~/src/components/loader/InPageLoader'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import { useLocation } from 'react-router-dom'
import SlideFromBottom from '~/src/components/mui-wrappers/animations/motion/SlideFromBottom'

const Orders = () => {
  const { pathname } = useLocation()

  const firstFetch = useRef<boolean>(true)
  const orders = useEoValue(ORDERS_BY_DATE_SELECTOR(true))
  const customer = useEoValue(CUSTOMER_STATE)
  const { fetch: fetchOrders, loading: isFetchingOrders } = useFetchAllOrders()

  useGetOrderTemplate()

  const _getIsLoading = (): boolean => {
    return isFetchingOrders || firstFetch.current
  }

  useAsyncEffect(async () => {
    await fetchOrders(customer?.id)
    firstFetch.current = false
  }, [ customer ])

  return (
    <>
      <DocumentTitleInjector pageName={'ORDERS'}/>

      {_getIsLoading() && <InPageLoader sx={{ mt: 0 }}/>}
      {!_getIsLoading() && (
        <SlideFromBottom
          pathname={pathname}
          y={10}>
          <ContentContainer sx={{ mb: 2 }}>
            {!!orders?.length && !_getIsLoading() && <OrderList orders={orders}/>}
            {!orders?.length && !_getIsLoading() && <NoOrderFound/>}
          </ContentContainer>
        </SlideFromBottom>
      )}
    </>
  )
}

export default Orders
